<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="page-container block-container">
      <div class="column left-block large-block">
        <h1>Domeinnaam overdragen: overnemen met code</h1>
        <CheckDomainInput/>
        <div>
          <p>
            <img
              class="framed-image"
              style="float: right; margin-left: 20px;"
              src="../../assets/images/img-hosting2.jpg"
              height="190"
              width="300"
              title="domeinnaam overdragen"
              alt="domeinnaam overdragen"
            >
            Een domeinnaam overdragen is eenvoudig, dit kan in een paar simpele stappen. Wij zorgen voor de daadwerkelijke overdracht van de domeinnaam, nadat u een paar stappen heeft doorlopen om de juiste gegevens op een rij te zetten.
          </p>
          <p>Heeft u een domeinnaam met <router-link to="/">pakket voor webhosting</router-link> en wilt u die overdragen? We zorgen er graag voor dat u het pakket wellicht met een andere domeinnaam kunt blijven gebruiken of dat we dit voor u stoppen, afhankelijk van uw wensen.</p>
          <p><i>“De overdracht van de domeinnaam verliep heel soepel. Duidelijk uitgelegd wat er moest gebeuren en vlot geregeld. Ook de nieuwe houder van het domein was er erg over te spreken en gaf aan de volgende domeinnaam ook bij Hosting on Demand te registreren,”</i> aldus een tevreden klant.</p>
        </div>
        <h2>Overdracht van een domeinnaam</h2>
        <p>Stap voor stap een domeinnaam overdragen:</p>
        <ol>
          <li>Geef ons aan dat u de domeinnaam wilt overdragen</li>
          <li>Vraag de overdrachtscode voor de domeinnaam aan</li>
          <li>Geef de nieuwe houder de code door</li>
          <li>Laat de domeinnaam opnieuw registreren na overdracht</li>
        </ol>
        <p><i>Tip: een domeinnaam (tijdelijk) niet meer nodig, maar daar wel de eigenaar van blijven? Het is dan niet nodig om de domeinnaam over te dragen, u kunt die bijvoorbeeld <router-link to="/domeinnamen/domeinnaam-doorsturen/">doorsturen</router-link>.</i></p>
        <p></p>
        <p>Wil iemand anders u een domeinnaam overdragen? U kunt de domeinnaam overnemen door de overdrachtscode bij de huidige houder op te vragen. Hij of zij kan dit doen bij het hostingbedrijf waar de domeinnaam is geregistreerd. Vervolgens kunt u online een domeinnaam registreren en tijdens dat proces de overdrachtscode invoeren. We weten op die manier dat u de domeinnaam mag overnemen van de huidige houder, zodat we die voor u kunnen registreren.</p>
        <h2>Domeinnaam overdragen</h2>
        <p>U kunt een domeinnaam overdragen op het moment dat u die zelf niet meer wenst te gebruiken of iemand anders die graag van u wil overnemen. Hiervoor heeft u een overdrachtscode nodig, die u specifiek kunt aanvragen op het moment dat u het domein graag wilt overdragen.</p>
        <p>Wij maken vervolgens de overdracht van het domein voor u in orde, zodat u daar zelf geen omkijken naar heeft. Het is voldoende om online aan te geven dat u het domein wilt laten overnemen, zodat we u de code kunnen toesturen die u met de nieuwe houder van het domein kunt delen.</p>
        <RandomQuote/>
      </div>
      <DomainNameRightSideBlocks/>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DomainNameRightSideBlocks from '@/components/DomainNameRightSideBlocks.vue';
import CheckDomainInput from '@/components/CheckDomainInput.vue';
import RandomQuote from '@/components/RandomQuote.vue';

export default {
  name: 'TransferDomainNameTakeOverWithCode',
  components: {
    Breadcrumbs,
    DomainNameRightSideBlocks,
    CheckDomainInput,
    RandomQuote,
  },
}
</script>